import * as React from "react"
import { graphql } from "gatsby"
import Layout from '../../components/Layout';
import Helmet from 'react-helmet';
import { Flex, Box, Heading, Text } from 'rebass';
import Gallery from '@browniebroke/gatsby-image-gallery'
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Breadcrumbs from '../../components/Breadcrumbs';
import strings from './strings.json';

const Collection17 = (props) => {
  const { collection17 } = props.data;
  const images = collection17.images.map(( node ) => node.childImageSharp)
  const { storeName, storeDescription } = props.data.site.siteMetadata.gatsbyStorefrontConfig;
  const { location } = props;
  const { title, description } = strings;

  return (
    <Layout>
      <GatsbySeo noindex={true} />
      <Helmet title={title} titleTemplate={`%s — ${storeName}`} defer={false} />
      <Flex flexWrap="wrap" px={2} pt={3} mx="auto">
        <Box
          sx={{
            display: 'block',
          }}
          pt={1}
        >
            <Breadcrumbs
              productTitle={collection17.title}
              separator="/"
            />
        </Box>
        <Box width={1} mb={2}>
          <Heading as="h1" fontSize={[30, 36, 42]} my={3}>
            {collection17.title}
          </Heading>
          <Text fontSize={[2, 3]}>{collection17.description}</Text>
        </Box>
        <Box width={1}>
          <Gallery images={images} />
        </Box>
      </Flex>
    </Layout>
  );
}

export default Collection17;

export const query = graphql`
  query {
    collection17 {
      title
      description
      images {
        name
        childImageSharp {
          thumb: gatsbyImageData(width: 300, height: 450, placeholder: BLURRED)
          full: gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    site {
      siteMetadata {
        gatsbyStorefrontConfig {
          storeName
          designer
        }
      }
    }
  }
`
